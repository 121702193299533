import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  title?: string
  duration?: string
  image?: ImageProps
}

export const Treatment = memo(function Treatment({
  image,
  duration,
  title,
}: Props) {
  return (
    <Container>
      <StyledImage {...image} />

      <Overlay />

      <Text>
        {title ? <Title>{title}</Title> : null}

        {duration ? <Duration>{duration}</Duration> : null}
      </Text>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:hover {
    & img {
      transform: scale(1.1);
    }
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
`

const Text = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4vw;
  padding-bottom: 4vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Title = styled.h3`
  font-size: 1.66vw;
  font-weight: 300;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`
const Duration = styled.p`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 30px;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  background: url('/icon_clock.svg') center left no-repeat;
  background-size: 20px auto;

  @media (max-width: 1439px) {
    font-size: 13px;
  }

  @media (max-width: 991px) {
    font-size: 12px !important;
  }
`
