import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Props as TreatmentProps, Treatment } from './treatment'

export interface Props {
  title?: string
  description?: string
  treatments?: TreatmentProps[]
}

export const Category = memo(function Category({
  description,
  treatments,
}: Props) {
  const [_currentSlide, setCurrentSlide] = useState(0)

  const [sliderMain, instanceRefMain] = useKeenSlider({
    loop: true,
    initial: 0,
    slides: { perView: 2, spacing: 45 },
    defaultAnimation: {
      duration: 1500,
    },
    breakpoints: {
      '(max-width: 991px)': {
        slides: { perView: 1.2, spacing: 5, origin: 'center' },
        loop: false,
        drag: true,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      let index = s.track.details.rel
    },
  })

  return (
    <Container>
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <SliderWrapper>
        <Slider ref={sliderMain} className="keen-slider">
          {treatments?.map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Treatment {...item} />
            </Slide>
          ))}
        </Slider>

        {treatments && treatments?.length > 2 ? (
          <>
            <Arrow
              className="arrow-left"
              onClick={(e) => {
                instanceRefMain.current?.prev()
              }}
            />
            <Arrow
              className="arrow-right"
              direction="R"
              onClick={(e) => {
                instanceRefMain.current?.next()
              }}
            />
          </>
        ) : null}
      </SliderWrapper>
    </Container>
  )
})

const Container = styled.div`
  top: 0;
  position: relative;
`

const Description = styled.div`
  text-align: center;
  margin-bottom: 2.5vw;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
`

const SliderWrapper = styled.div`
  position: relative;

  .arrow-left {
    top: 50%;
    left: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow-right {
    top: 50%;
    right: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(50%, -50%, 0);
  }

  @media (max-width: 991px) {
    width: calc(100% + 65px);
    margin-left: -35px;

    .arrow-left,
    .arrow-right {
      display: none;
    }
  }
`
const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  height: 30vw;

  @media (max-width: 991px) {
    height: 60vw;
  }
`
const Slide = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`
