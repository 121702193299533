import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo, useState } from 'react'

import { Category, Props as CategoryProps } from './category'

export interface Props {
  pretitle?: string
  title?: string
  description?: string
  button?: ButtonProps
  treatmentsCategories?: CategoryProps[]
}

export const TreatmentsList = memo(function TreatmentsList({
  pretitle,
  title,
  description,
  button,
  treatmentsCategories,
}: Props) {
  const [currentCategory, setCurrentCategory] = useState(0)
  return (
    <Container>
      <Top>
        {pretitle ? (
          <FadeInUp>
            <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
          </FadeInUp>
        ) : null}
        {title ? <Title text={title} /> : null}
      </Top>
      {treatmentsCategories ? (
        <FadeInUp>
          <Nav>
            {treatmentsCategories.map((item, index) => (
              <NavEl
                key={index}
                className={currentCategory == index ? 'active' : undefined}
                onClick={() => {
                  setCurrentCategory(index)
                }}
              >
                {item?.title}
              </NavEl>
            ))}
          </Nav>
          <CategoriesWrapper>
            {treatmentsCategories.map((item, index) => (
              <CategoryWrapper
                key={index}
                className={currentCategory == index ? 'active' : undefined}
              >
                <Category key={index} {...item} />
              </CategoryWrapper>
            ))}
          </CategoriesWrapper>
        </FadeInUp>
      ) : null}

      <Bottom>
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {button ? (
          <FadeInUp>
            <StyledButton {...button} />
          </FadeInUp>
        ) : null}
      </Bottom>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.66vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Top = styled.div``

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 2.2vw;
  margin: 2vw 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const Nav = styled.nav`
  text-align: center;
  margin: 2.5vw 0;
  border-top: 1px solid ${({ theme }) => theme.colors.variants.neutralLight2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.variants.neutralLight2};

  @media (max-width: 991px) {
    margin: 20px 0;
  }
`
const NavEl = styled.div`
  padding: 15px 20px;
  width: 10vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border-bottom: 2px solid transparent;

  &.active {
    font-weight: 500;
    opacity: 1;
    pointer-events: none;
    border-color: ${({ theme }) => theme.colors.variants.neutralDark2};
  }

  &:hover {
    opacity: 1;
    font-weight: 500;
    border-color: ${({ theme }) => theme.colors.variants.neutralDark2};
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 10px 15px;
    font-size: 14px;
  }
`

const CategoriesWrapper = styled.div`
  position: relative;
`

const CategoryWrapper = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  transition: all 200ms linear;

  &:first-of-type {
    position: relative;
  }

  &.active {
    opacity: 1;
    z-index: 3;
    pointer-events: auto;
    transition: all 200ms 200ms linear;
  }
`

const Bottom = styled.div``

const Description = styled.div`
  text-align: center;
  margin-top: 7vw;
  p {
    font-size: 1.45vw;
    line-height: 2.1vw;
  }

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`

const StyledButton = styled(Button)`
  margin: 2vw auto 0 auto;

  @media (max-width: 991px) {
    margin: 30px auto 0 auto;
  }
`
